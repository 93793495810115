import styled from '@emotion/styled';
import { Box, Flex } from 'rebass';

import { breakpoints, colors, space, textStyles } from 'utils/rebass-theme';

export const SupplierCardLineItem = styled(Flex)`
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
`;

export const AddButton = styled(Box)`
  width: 100%;
  height: 35px;
`;

export const PriceCell = styled.div`
  text-align: right;
  margin-left: auto;
  height: 34px;
`;

export const TotalPriceCell = styled(Flex)`
  ${textStyles.body};
  color: ${colors.darkGrey};
  justify-content: flex-end;
  flex-direction: column;
  line-height: 1.2;
`;

export const PerUnitCell = styled(Flex)`
  ${textStyles.footnote};
  color: #828282;
  font-weight: 400;
  margin-top: 2px;
`;

export const GrowerCell = styled(Flex)`
  ${textStyles.footnote};
  color: ${colors.darkGrey};
  margin-left: 8px;
`;

export const GrowerRow = styled(Flex)`
  align-items: center;
`;

export const Label = styled(Flex)`
  ${textStyles.footnote};
  color: ${colors.darkGrey};
  font-weight: 400;
  margin-right: 8px;
`;

export const BoldLabel = styled(Label)`
  font-weight: 700;
`;

export const Value = styled(Flex)`
  ${textStyles.footnote};
  color: ${colors.darkGrey};
  justify-content: center;
`;

export const PrimaryAttributesContainerDesktop = styled(Box)`
  margin-bottom: 5px;

  @media (max-width: ${breakpoints.small}) {
    margin-bottom: 4px;
    display: none;
  }
`;

export const PrimaryAttributesContainerMobile = styled(Box)`
  margin-bottom: 5px;
  display: none;

  @media (max-width: ${breakpoints.small}) {
    margin-bottom: 2px;
    display: block;
  }
`;

export const AttributesWrapper = styled(Flex)`
  @media (max-width: ${breakpoints.small}) {
    flex-direction: column;
    margin-left: -5px;
  }
`;

export const MultiplesOfDesktop = styled(Flex)`
  @media (max-width: ${breakpoints.small}) {
    display: none;
  }
`;

export const MultiplesOfMobile = styled(Flex)`
  display: none;

  @media (max-width: ${breakpoints.small}) {
    display: flex;
    margin-top: 3px;
    margin-left: 6px;
    border-left: solid 1px ${colors.grey};
  }
`;

export const AttributesContainer = styled(Flex)`
  flex-direction: column;
  padding-left: 10px;

  @media (max-width: ${breakpoints.small}) {
    padding-left: 0;
  }
`;

export const KeyAttribute = styled(Flex)`
  @media (max-width: ${breakpoints.small}) {
    margin-bottom: 4px;
  }
`;

export const KeyAttributeItem = styled(Box)`
  ${textStyles.footnote};
  font-size: 14px;
  margin-top: -3px;
  color: ${colors.darkGrey};
  font-weight: 800;
  padding-left: 3px;
  padding-right: 3px;

  @media (max-width: ${breakpoints.small}) {
    display: flex;
    align-items: center;
    padding-left: 6px;
    padding-right: 6px;
  }
`;

export const AttributeItem = styled(Box)<{ isBold: boolean }>`
  ${textStyles.footnote};
  color: ${colors.darkGrey};
  font-weight: ${({ isBold }): number => isBold ? 800 : 300};
  padding-left: 8px;
  padding-right: 8px;
  border-left: solid 1px ${colors.grey};
  display: flex;
  align-items: center;

  @media (max-width: ${breakpoints.small}) {
    padding-left: 6px;
    padding-right: 6px;

    &:first-of-type {
      border-left: none;
    }
  }
`;

export const LastRow = styled(Flex)`
  margin: 12px 0;
`;

export const Cell = styled(Flex)`
  margin-right: 18px;
  align-items: center;
`;

export const CardHeader = styled(Flex)<{ isSelected: boolean }>`
  ${textStyles.body};
  color: ${colors.shade60};
  width: 100%;
  justify-content: space-between;
  align-items: ${({ isSelected }): string => isSelected ? 'start' : 'center'};
  margin-bottom: ${({ isSelected }): string => isSelected ? '10px' : '0'};
  padding-top: ${({ isSelected }): string => isSelected ? '3px' : '0'};

  @media (max-width: ${breakpoints.small}) {
    align-items: start;
  }
`;

export const CardBody =  styled.div`
  width: 100%;

  @media (max-width: ${breakpoints.small}) {
    margin-left: 0;
  }
`;

export const BuyControlsContainer = styled(Flex)`
  width: 100%;
  justify-content: end;
  margin-top: ${space[2]}px;
`;

export const ButtonCell = styled(Flex)`
  padding-left: 14px;
  width: 100%;
  max-width: 152px;
`;
